import React from 'react';
import Gofundme from '../../image/GoFundMe_logo.png';
import AccountImage from '../../image/Account_Image.png';
import event1 from '../../image/events-1.jpg';
import event2 from '../../image/events-2.jpg';
import event3 from '../../image/events-3.jpg';




function Events() {
    return (
        <section class=" body-font h-75vh">
            <div className='flex flex-col'>
                <div class="container px-5 py-14 mx-auto text-gray-600">
                    <div class="flex flex-col w-full mb-4 mt-10">
                        <div class="lg:w-1/2 w-full mb-4 lg:mb-0">
                            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Upcoming Gatherings</h1>
                            <div class="h-1 w-20 bg-indigo-500 rounded">

                            </div>
                        </div>
                        <p class=" w-4/4 leading-relaxed text-gray-500">Check out our online calendar to see what's happening each week and weekend. We've got Sunday services, prayer meetings, youth group activities and many more events!</p>
                    </div>
                    <div class="flex flex-wrap justify-center ">
                        <div class="xl:w-1/3 md:w-2/2 p-3 flex ">
                            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 ml-4 ">
                                <a>
                                    <img class="rounded-t-lg" src={event1} alt="" />
                                </a>
                                <div class="px-3 py-4">
                                    <a>
                                        <h5 class="text-gray-900 font-bold text-xl mb-2 tracking-tight  dark:text-white">የባለትዳሮች ቀን</h5>
                                    </a>
                                    <p class="font-normal text-gray-700 mb-3 dark:text-gray-400  line-clamp-6">የአያት መካነ ኢየሱስ ማ/ም የቤተሰብ አገልግሎት ክፍል   የባለትዳሮች ፕሮግራም በየአመቱ የሚደረግ  ፕሮግራም ነው፡፡</p>

                                </div>
                            </div>
                        </div>

                        <div class="xl:w-1/3 md:w-2/2 p-3 flex ">
                            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 ml-4 ">
                                <a>
                                    <img class="rounded-t-lg" src={event2} alt="" />
                                </a>
                                <div class="px-3 py-4">
                                    <a>
                                        <h5 class="text-gray-900 font-bold text-xl mb-2 tracking-tight  dark:text-white">ዓመታዊ የእረኞች ቀን</h5>
                                    </a>
                                    <p class="font-normal text-gray-700 mb-3 dark:text-gray-400  line-clamp-6">የአያት መካነ ኢየሱስ ማ/ም የእረኞች አገልግሎት ክፍል ለእረኞች በየአመቱ የሚደረግ ፕሮግራም ነው፡፡</p>

                                </div>
                            </div>
                        </div>

                        <div class="xl:w-1/3 md:w-2/2 p-3 flex ">
                            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 ml-4 ">
                                <a>
                                    <img class="rounded-t-lg" src={event3} alt="" />
                                </a>
                                <div class="px-3 py-4">
                                    <a>
                                        <h5 class="text-gray-900 font-bold text-xl mb-2 tracking-tight  dark:text-white">የትምህርት እና የውይይት ጊዜ</h5>
                                    </a>
                                    <p class="font-normal text-gray-700 mb-3 dark:text-gray-400  line-clamp-6">ለአያት መካነ ኢየሱስ ማ፨ም ምዕመናን ሁሉ የተዘጋጀ የትምህርት እና የውይይት ጊዜ ነው፡፡</p>

                                </div>
                            </div>
                        </div>

                        {/* <div class="xl:w-1/3  md:w-2/2 p-3 flex ">
                            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 ml-4 ">
                                <a>
                                    <img class="rounded-t-lg" src="https://flowbite.com/docs/images/blog/image-1.jpg" alt="" />
                                </a>
                                <div class="p-5">
                                    <a>
                                        <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2 dark:text-white">Noteworthy technology acquisitions 2021</h5>
                                    </a>
                                    <p class="font-normal text-gray-700 mb-3 dark:text-gray-400 line-clamp-2">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
                                    <a class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Read more
                                        <svg class="-mr-1 ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </a>
                                </div>
                            </div>
                        </div> */}

                        {/* <div class="xl:w-1/3 md:w-2/2 p-3 flex ">
                            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 ml-4 ">
                                <a>
                                    <img class="rounded-t-lg" src="https://flowbite.com/docs/images/blog/image-1.jpg" alt="" />
                                </a>
                                <div class="p-5">
                                    <a>
                                        <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2 dark:text-white">Noteworthy technology acquisitions 2021</h5>
                                    </a>
                                    <p class="font-normal text-gray-700 mb-3 dark:text-gray-400 line-clamp-2">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
                                    <a class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Read more
                                        <svg class="-mr-1 ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </a>
                                </div>
                            </div>
                        </div> */}


                    </div>

                </div>
            </div>
        </section >

    )
}

export default Events